<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <material-card color="#4e4e4e" title="Mala">
          <v-toolbar color="#FFF">
            <v-text-field
              hide-details
              prepend-icon
              single-line
              v-model="pesquisa"
            ></v-text-field>
            <v-btn color="#4e4e4e" text @click="findCliente()">Buscar</v-btn>
          </v-toolbar>
          <v-data-table :headers="headers" :items="items">
            <template slot="headerCell" slot-scope="{ header }">
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template slot="items" slot-scope="{ item }">
              <td>{{ item.nome }}</td>
              <!-- <td>{{ item.cliente.nome }}</td> -->
              <td>{{item.entrega}}</td>
              <td>{{item.devolucao}}</td>
              <td v-if="item.status == 0" style="color: green">
                INICIADO
              </td>
              <td v-else-if="item.status == 1" style="color: gray">
                FINALIZADO
              </td>
              <td v-else-if="item.status == 2" style="color: red">
                CANCELADO
              </td>
              <td v-else-if="item.status == 3" style="color: blue">
                EM OBSERVAÇÃO
              </td>
              <td v-else-if="item.status == 4" style="color: #9e82b0">
                SOLICITADO RETIRADA
              </td>
              <td v-else-if="item.status == 5" style="color: #9e82b0">
                SOLICITADO DEVOLUÇÃO
              </td>
              <td v-else-if="item.status == 6" style="color: #9e82b0">
                EM TRANSPORTE
              </td>
              <td style="width: 50px; height: 50px; object-fit: contain">
                <v-toolbar color="rgba(0,0,0,0)" flat>
                  <v-btn
                    color="rgba(0,0,0,0)"
                    flat
                    @click="getMalaEspecifica(item._id)"
                    ><v-icon color="tertiary">mdi-view-list</v-icon></v-btn
                  >
                </v-toolbar>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>

      <v-snackbar
        color="#000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbar"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>Campanha atualizado com sucesso!</div>
        <v-icon size="16" @click="snackbar = false">mdi-close-circle</v-icon>
      </v-snackbar>

      <v-snackbar
        color="#BA0000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbarerror"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>
          Verifique os dados e tente novamente
          <br />Verifique a conexão com a internet!
        </div>
        <v-icon size="16" @click="snackbarerror = false"
          >mdi-close-circle</v-icon
        >
      </v-snackbar>
    </v-layout>

    <!-- <v-dialog v-model="comprasrealizadasdialog" width="900">
      <v-list v-if="comprasrealizadas.length > 0">
        <v-card-text>Compras realizadas</v-card-text>
        <v-list-group
          v-for="item in comprasrealizadas"
          :key="item.codigoVenda"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title style="font-weight: bold"
                  >Código de Venda: {{ item.codigoVenda }}</v-list-tile-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <v-list-tile v-for="subItem in item.produtos" :key="subItem.title">
            <v-list-tile-content>
              <v-list-tile-title
                >{{ subItem.id_prod.nome }} - R$
                {{ subItem.valor.toFixed(2).toString().replace(".", ",") }} -
                Qnt: {{ subItem.qntSolicitada }}</v-list-tile-title
              >
            </v-list-tile-content>

            <v-list-tile-action>
              <v-icon>{{ subItem.action }}dsds</v-icon>
            </v-list-tile-action>
          </v-list-tile>
        </v-list-group>
      </v-list>
      <v-list v-else>
        <v-card-text>Nenhuma compra realizada</v-card-text>
      </v-list>
    </v-dialog> -->

    <v-dialog v-if="selecionado" v-model="showMala" width="900">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >{{ selecionado.mala.nome }}</v-card-title
        >
        <v-card-text v-if="selecionado">
          Nome: {{selecionado.cliente.nome}}
        </v-card-text>

        <v-card-text v-if="selecionado">
          Telefone: {{selecionado.cliente.telefone}}
        </v-card-text>
        <v-card-text v-if="selecionado">
          Endereço: {{selecionado.mala.endereco.rua}} - {{selecionado.mala.endereco.numero}}<br/>
          {{selecionado.mala.endereco.bairro}}, {{selecionado.mala.endereco.cidade}}/{{selecionado.mala.endereco.estado}}<br />
          <p v-if="selecionado.mala.endereco.complemento">Complemento: {{selecionado.mala.endereco.complemento}}</p><br />
          <p v-if="selecionado.mala.endereco.identificacao">Destinatário: {{selecionado.mala.endereco.identificacao}}</p><br/>
          <p v-if="selecionado.mala.endereco.referencia">Referência: {{selecionado.mala.endereco.referencia}}</p>
        </v-card-text>
        <!-- <v-card-text v-if="selecionado">
          Tipo:
          <v-flex
            v-if="selecionado.user && selecionado.user.tipo"
            xs12
            sm6
            d-flex
          >
            <select
              v-if="selecionado.user && selecionado.user.tipo"
              v-model="selecionado.user.tipo"
              style="border-width: 1"
            >
              <option
                v-for="tipo in this.tipos"
                v-bind:key="tipo.valor"
                :value="tipo.valor"
              >
                {{ tipo.nome }}
              </option>
            </select>
          </v-flex>
        </v-card-text> -->

        <v-card-text v-if="selecionado">
          Status:
          <v-flex v-if="selecionado.mala" xs12 sm6 d-flex>
            <select v-model="selecionado.mala.status" @change="atualizarStatusMala" style="border-width: 1">
              <option
                v-for="tipo in this.tipos_status"
                v-bind:key="tipo.valor"
                :value="tipo.valor"
              >
                {{ tipo.nome }}
              </option>
            </select>
          </v-flex>
        </v-card-text>

        <!-- <v-card-text v-if="selecionado">
          Descrição do status:
          <v-text-field v-model="selecionado.status.descricao"></v-text-field>
        </v-card-text> -->

        <!-- <input
          class="ml-3"
          type="file"
          id="file"
          ref="file"
          v-on:change="handleFileUpload()"
        /> -->

        <v-card-text v-if="selecionado">
          <b>Produtos:</b>
        </v-card-text>

        <!-- <v-text-field
          v-model="codigoDeBarras"
          class="ml-3 mr-5"
          placeholder="Adicionar Produto"
        ></v-text-field>
        <v-btn color="primary" class="ml-3" @click="adicionarProduto()"
          >Adicionar</v-btn
        > -->
        <div v-if="selecionado">
          <v-data-table
            :headers="header_produtos"
            :items="selecionado.mala.produtos"
            :items-per-page="5"
            class="elevation-1"
          >
            <template slot="items" slot-scope="{ item }">
              <td>{{ item.produto.nome }}</td>
              <td>{{ item.produto.codigoDeBarras }}</td>
              <td>{{item.produto.marca}}</td>
              <td>R$ {{item.produto.preco.preco_venda.toFixed(2).replace('.', ',')}}</td>
              <td>{{item.qntSolicitada}}</td>
            </template>
          </v-data-table>
        </div>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="() => {showMala = false; selecionado = null; }"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--<v-dialog v-model="dialogCriar" width="900">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >Criar Campanha</v-card-title
        >
        <v-card-text>
          Nome:
          <v-text-field v-model="criar.nome"></v-text-field>
        </v-card-text>
        <v-card-text>
          Descrição:
          <v-text-field v-model="criar.descricao"></v-text-field>
        </v-card-text>

        <v-card-text>
          Porcentagem:
          <v-text-field
            v-model="criar.porcentagem"
            type="number"
          ></v-text-field>
        </v-card-text>
        <v-row justify="center">
          <v-card-text>Início:</v-card-text>

          <v-date-picker
            v-model="criar.begin"
            class="ml-3"
            dark
          ></v-date-picker>
        </v-row>
        <v-row justify="center">
          <v-card-text>Fim:</v-card-text>
          <v-date-picker v-model="criar.end" class="ml-3" dark></v-date-picker>
        </v-row>
        <br />

        <input
          class="ml-3 mt-4"
          type="file"
          id="file"
          ref="file"
          v-on:change="handleFileUpload()"
        />

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="criarCampanha()">Criar</v-btn>
        </v-card-actions> -->
      <!-- </v-card> -->
    <!-- </v-dialog> -->
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    dialogeditar: false,
    dialogCriar: false,
    malas: [],
    showMala: false,
    pesquisa: "",
    codigoDeBarras: "",
    file: "",
    headers: [
      {
        sortable: false,
        text: "Nome",
        value: "nome",
      },
      // {
      //   sortable: false,
      //   text: "Cliente",
      //   value: "cliente",
      // },
      {
          sortable: false,
          text: 'Entrega',
          value:'entrega'
      },
      {
          sortable: false,
          text: 'Devolução',
          value:'devolucao'
      },
      {
        sortable: false,
        text: "Status",
        align: "center",
        value: "status",
      },
      {
        sortable: false,
        text: "Ações",
      },
    ],
    header_produtos: [
        {
            sortable: false,
            text: 'Nome',
            value: 'nome'
        },
        {
            sortable: false,
            text: 'Codigo de Barras',
            value: 'codigoDeBarras'
        },
        {
            sortable: false,
            text: 'Marca',
            value: 'marca'
        },
        {
            sortable: false,
            text: 'Valor',
            value: 'valor'
        },
        {
          sortable: false,
          text: 'Quantidade',
          value: 'quantidade'
        }
    ],
    produtos: [],
    selecionado: null,
    tipos_status: [
      {
        nome: "Iniciado",
        valor: 0,
      },
      {
        nome: "Finalizado",
        valor: 1,
      },
      {
        nome: "Cancelado",
        valor: 2,
      },
      {
        nome: "Em observação",
        valor: 3,
      },
      {
        nome: "Solicitado Retirada",
        valor: 4,
      },
      {
        nome : "Solicitado Devolução",
        valor: 5
      },
      {
        nome: 'Em Transporte',
        valor: 6
      }
    ],
    snackbar: false,
    snackbarerror: false,
    select: null,
    selecionado: null,
    items: [],
    bottom: false,
    top: false,
    left: false,
    right: false,
  }),
  beforeMount() {
    this.getMalas();
  },
  methods: {
    async getMalas() {
      try {
        let res = await this.$http.get("/malas/");
        this.items = res.data;
        console.log(res.data);
      } catch (err) {
        alert(err.error);
      }
    },
    async atualizarStatusMala() {
      try {
        let m = await this.$http.put(`/malas/update/status/${this.selecionado.mala._id}`, {
          status: this.selecionado.mala.status
        })
        alert("Status atualizado");
      } catch (err) {
        console.log(err);
        alert(err.error);
      }
    },
    async deletarMala() {
      try {
      } catch (err) {
        alert(err.error);
      }
    },
    async getMalaEspecifica(id) {
      try {
          let mala = await this.$http.get(`/malas/minhamala/${id}`);
          this.showMala = true;
          this.selecionado = mala.data;
      } catch (err) {
        alert(err.error);
      }
    },
    async atualizarMala() {
      try {
      } catch (err) {
        alert(err.error);
      }
    },
    async finalizarMala() {
      try {
      } catch (err) {
        alert(err.error);
      }
    },
  },
};
</script>
